import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>404 - Page Not Found</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container">
				<main className="textCenter">
					<p>Sorry 😔, we couldn’t find what you were looking for. Please return to the <a href="/">homepage</a> or use the navigation above.</p>
				</main>
			</div>
		</section>
	</Layout>
  )
}
export default NotFoundPage

export const Head = () => (
	<Seo
		title="404 Page | Habitat For Humanity Northwoods Wisconsin"
	/>
)

